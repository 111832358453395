<template>
  <div class="grid">
    <div>
      <article>
        <header><strong>Connect to the Panel</strong></header>
        <div id="fb-ui-auth"></div>
      </article>
    </div>
  </div>
</template>

<script>

import firebase from 'firebase/compat/app';
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'
import Vue from 'vue'

export default {
  name: 'Home',
  props: [],
  components: {
  },
  mounted() {
    const auth = Vue.firebase.auth;
    let ui = new firebaseui.auth.AuthUI(auth);
    if (document.getElementById('fb-ui-auth').innerHTML === "") {
      console.log(document.getElementById('fb-ui-auth').innerHTML === "")
      console.log("Showing ui");
      ui.start('#fb-ui-auth', {
        callbacks: {
          signInSuccessWithAuthResult: function() {
            console.log("Success")
            return true;
          },
          signInFailure: function(error) {
            console.log("Error")
            console.log(error);
            console.log(error.code);
          }
        },
        signInFlow: 'popup',
        signInSuccessUrl: '/home',
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID,
          firebase.auth.GithubAuthProvider.PROVIDER_ID
        ],
      });      
    }
  }
}
</script>

<style>
ul li.firebaseui-list-item {
  list-style: none;
}
</style>